import { InfoCircledIcon } from '@radix-ui/react-icons';
import React,{useState} from 'react';
import { useRouter } from "next/navigation";
import DynamicImage from './DynamicImage';
interface AssistantCardProps {
  id: string;
  displayName: string;
  userProfilesHandler: () => void; 
  userDetails?: any;
  dashboardHandler: () => void;
}

const AssistantCard: React.FC<AssistantCardProps> = ({id, displayName, userProfilesHandler, dashboardHandler, userDetails }) => {

  return (
    <div className='w-full'>
      <div className="flex justify-between items-center p-8 w-full h-24 bg-gradient-to-r from-[#c2c8ff] to-[#fdfbfb] rounded-[20px] border-2 border-[#a7aefb]">
        <div className="w-2/5 max-md:w-full flex items-center">
          <DynamicImage src="/dscreen-logo.svg" width={75} height={75} alt="icon"/>
          <div className="text-black ms-8 text-2xl font-semibold font-['DM Sans']">{displayName}</div>
        </div>
        {
          !userDetails.isSuperAdmin && userDetails.role == "Admin" && 
          <button
            onClick={(e) => {
              e.stopPropagation(); // Prevent event from bubbling up
              userProfilesHandler(); // Pass the assistant id when deleting
            }}
            className="w-[24%] flex justify-center max-md:hidden items-center text-white h-10 bg-[#5260eb] rounded-full">
              Users
          </button>

        }
        {
          !userDetails.isSuperAdmin && userDetails.role == "Admin" && 
          <button
            onClick={(e) => {
              e.stopPropagation();
              dashboardHandler();
            }} 
            className="w-[24%] h-10 flex justify-center max-md:hidden items-center text-white bg-[#1bb560] rounded-full">
              Reports
          </button>
        }
      </div>
      {
          !userDetails.isSuperAdmin && userDetails.role == "Admin" && 
          <button
            onClick={(e) => {
              e.stopPropagation(); // Prevent event from bubbling up
              userProfilesHandler(); // Pass the assistant id when deleting
            }}
            className="w-full max-md:flex justify-center hidden py-1 my-4 items-center text-white h-10 bg-[#5260eb] rounded-full">
              Users
          </button>

        }
        {
          !userDetails.isSuperAdmin && userDetails.role == "Admin" && 
          <button
            onClick={(e) => {
              e.stopPropagation();
              dashboardHandler();
            }} 
            className="w-full h-10 max-md:flex justify-center py-1 my-4 hidden items-center text-white bg-[#1bb560] rounded-full">
              Reports
          </button>
        }
    </div>
   
  );
};

export default AssistantCard;

