'use client'; // If using Next.js App Router

import React from 'react';
interface LoadingProps {
  loadingtext?: boolean;
  loadingContent?: string;
}
const Loading: React.FC<LoadingProps> = ({loadingtext=false, loadingContent=""}) => {
  const loaderStyle: React.CSSProperties = {
    width: '3rem',
    height: '3rem',
    position: 'relative' as 'relative', // Cast to ensure TypeScript compatibility
    animation: 'spin988 2s linear infinite',
  };

  const circleStyle: React.CSSProperties = {
    width: '1.2rem',
    height: '1.2rem',
    backgroundColor: '#333',
    borderRadius: '50%',
    position: 'absolute' as 'absolute', // Cast to ensure TypeScript compatibility
  };

  return (
    <div className="fixed inset-0 bg-gray-800 flex flex-col items-center bg-opacity-50 justify-center h-screen">
      <div style={loaderStyle}>
        <div style={{ ...circleStyle, top: 0, left: 0 }}></div>
        <div style={{ ...circleStyle, top: 0, right: 0 }}></div>
        <div style={{ ...circleStyle, bottom: 0, left: 0 }}></div>
        <div style={{ ...circleStyle, bottom: 0, right: 0 }}></div>
      </div>
      {loadingtext && <div className='mt-4 text-xl text-white'>{loadingContent}</div>}
      <style jsx>{`
        @keyframes spin988 {
          0% {
            transform: scale(1) rotate(0);
          }
          20%, 25% {
            transform: scale(1.3) rotate(90deg);
          }
          45%, 50% {
            transform: scale(1) rotate(180deg);
          }
          70%, 75% {
            transform: scale(1.3) rotate(270deg);
          }
          95%, 100% {
            transform: scale(1) rotate(360deg);
          }
        }
      `}</style>
    </div>
  );
};

export default Loading;