"use client"
import React, { useState, useEffect, useRef,Dispatch,SetStateAction } from "react";
import Head from 'next/head';
import { useSession, signOut } from "next-auth/react";
import { useRouter } from "next/navigation";
import axios from "axios";
import Link from "next/link";
import toast from "react-hot-toast";
import Loading from "@/components/ui/loading";
import AssistantCard from "@/components/ui/assitantCard";
import { useDataContext } from "@/context/dataContext";
import { Header } from '@/components/Header';
import SuperAdminPage from "@/components/superAdminpage";
import EvaluatorPage from "@/components/evaluatorPage";


export default function Home() {
    const { status } = useSession();
    const [isUser, setIsUser] = useState(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [isEvaluator, setIsEvaluator] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isSuperAdmin, setisSuperAdmin] = useState(false);
    const [userDetails, setUserDetails] = useState(null);
    const [quizDetails, setQuizDetails] = useState(null);
    const { setUserProfiles, setQuizDisplayName, setOwnerEmail, setUserRole, apiStatus, setApiStatus } = useDataContext();
    const router = useRouter();
    useEffect( () => {
        if (status === "unauthenticated") {
            router.push("/login");
        } else if (status !== 'loading') {
            getUserDetails();
        }
    }, [status])

    // useEffect(() => {
    //     if (apiStatus === 'pending') {
    //         toast('Record saving is in progress. And will be availble in the reports page shortly.', { id: 'api-status' });
    //     } else if (apiStatus === 'completed') {
    //         toast.success('Record saved successfully!', { id: 'api-status' });
    //         setApiStatus(null); // Reset status
    //     } else if (apiStatus === 'error') {
    //         toast.error('API request failed!', { id: 'api-status' });
    //         setApiStatus(null); // Reset status
    //     }
    // }, [apiStatus]);

    const getUserDetails = async () => {
        const response = await axios.get('/api/user-details');
        const userDetails = response.data.user;
        setUserDetails(userDetails);
        setUserRole(userDetails.role);
        setLoading(false);
        if (userDetails.isSuperAdmin) {
            setisSuperAdmin(prev => true);
        } else if (userDetails.role === "Admin") {
            setIsAdmin(prev => true);
        } else if (userDetails.role === "Evaluator") {
            setIsEvaluator(prev => true);
        } else {
            setIsUser(prev => true)
        }
    }

    useEffect(() => {
        if (!isSuperAdmin && isAdmin) {
            getQuizDetails();
        }
    }, [isAdmin]);

    const getQuizDetails = async () => {
        const response = await axios.get(`api/quiz?email=${userDetails.email}`);
        if (response.status === 404) {
            toast.error('No quizzes found for this ownerEmail')
        }
        const Quizzesdetails = response.data.quizDetails;
        setQuizDetails(Quizzesdetails);
    }

    const loadCreateProfileUI = (displayName) => {
        if (userDetails.role == "Admin") {
            setUserProfiles(userDetails.profiles);
            setQuizDisplayName(displayName);
            setOwnerEmail(userDetails.email);
            router.push(`/profiles`);   
        } 
    }
    
    const goToReportsPage = (displayName) => {
        setQuizDisplayName(displayName);
        setOwnerEmail(userDetails.email);
        router.push('/reports');
    }

    return (
        <div>
            <Head>
                <title>D-screen</title>
            </Head>
            <main className="w-full">
                {(status === "loading" || loading) && (
                    <div className="fixed inset-0 z-50 flex justify-center items-center">
                        <Loading /> {/* Loading spinner */}
                    </div>
                )}
                {!loading && isSuperAdmin &&
                    <div>
                        <Header userRole={userDetails.role}/>
                        <SuperAdminPage userDetails={userDetails}/>
                    </div>
                }
                {!loading && isAdmin &&
                <>
                    <Header userRole={userDetails.role}/>
                    <div className="px-20 py-10 max-md:px-6 max-md:py-3">
                        <div className="text-[#496188] max-md:text-2xl text-4xl font-semibold font-['DM Sans']">Apps Created for You</div>
                        <div className="w-full flex flex-col gap-5 text-gray-800">
                            <div className="flex flex-wrap flex-col gap-4 justify-center mt-8 items-center sm:justify-start sm:items-start">
                                {quizDetails && 
                                quizDetails.map((quiz) => (
                                    <div key={quiz._id} className="w-full md:w-full flex">
                                        <AssistantCard
                                        id = {userDetails._id}
                                        displayName={quiz.displayName}
                                        userProfilesHandler= {() => loadCreateProfileUI(quiz.displayName)}
                                        dashboardHandler={() => goToReportsPage(quiz.displayName)}
                                        userDetails={userDetails}
                                    />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </>
                }
                {
                    !loading && isEvaluator &&
                    <div>
                        <Header userRole={userDetails.role}/>
                        <EvaluatorPage userDetails={userDetails}/>
                    </div>
                }
            </main>
        </div>
    );
}
