"use client";

import { createContext, useState, useContext } from "react";

const DataContext = createContext(null);

export const DataProvider = ({ children }) => {
  const [userProfiles, setUserProfiles] = useState(null);
  const [quizDisplayName, setQuizDisplayName] = useState(null);
  const [ownerEmail, setOwnerEmail] = useState(null);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [apiStatus, setApiStatus] = useState(null);
  const [recentlyRecordedProfile, setRecentlyRecordedProfile] = useState(null);

  return (
    <DataContext.Provider value={{ userProfiles, setUserProfiles, quizDisplayName, setQuizDisplayName, apiStatus, setApiStatus, ownerEmail, setOwnerEmail, selectedProfile, setSelectedProfile, setUserRole, userRole, setRecentlyRecordedProfile, recentlyRecordedProfile }}>
      {children}
    </DataContext.Provider>
  );
};

export const useDataContext = () => useContext(DataContext);
